import Helper from "@/untils/common";

export default {
    namespaced: true,
    state: {
        cars: sessionStorage.getItem('cars') ? sessionStorage.getItem('cars') : null,
        plan: sessionStorage.getItem('plan') ? sessionStorage.getItem('plan') : null,
    },
    getters: {
        getCars: (state: { cars: any; })=>{
            if(Helper.getVal(state.cars) != null){
                return JSON.parse(state.cars);
            }

            return [];
        },

        getPlan: (state: {plan:any}) => {
            if(Helper.getVal(state.plan) != null){
                return JSON.parse(state.plan);
            }

            return {};
        },
    },
    mutations: {
        /* PLAN */
        PLAN_SAVE(state: {plan:any}, plan:any){
            state.plan = plan;
            sessionStorage.setItem('plan', JSON.stringify(state.plan));
        },

        /* CAR */
        CAR_ADD(state: {cars:Array<any>},  car:any){
            state.cars.push(car);
            sessionStorage.setItem('cars', JSON.stringify(state.cars));
        },
        CAR_UPDATE(state:{cars:Array<any>}, car:any){
            let _index = state.cars.findIndex((item)=>{
                return item.id == car.id;
            });

            if(_index > -1){
                state.cars.splice(_index, 1, car);
                sessionStorage.setItem('cars', JSON.stringify(state.cars));
            }
        },
        CAR_REMOVE(state: {cars:Array<any>}, id:string){
            state.cars = state.cars.filter((item)=>{
                return item.id != id;
            })

            sessionStorage.setItem('cars', JSON.stringify(state.cars));
        },
        CAR_CLEAR(state: {cars:Array<any>}){
            state.cars = [];
            sessionStorage.setItem('cars', JSON.stringify(state.cars));
        }
    },
    actions: {
        PLAN_SAVE(context:any, obj:any){
            context.commit('PLAN_SAVE', obj);
        },
        CAR_ADD(context:any, obj:any){
            if(Helper.getVal(context.state.cars)== null){
                context.state.cars = [];
            }

            let _car = context.state.cars.find((item: { id: any; })=>{
                return item.id == obj.data.id;
            });

            if(!_car){
                if(context.state.cars.length >= 100){
                    if(obj.callback!= null){
                        obj.callback({type:'info', message:'选车单已满，请清除一些条目再重新添加'});
                    }
                }else{
                    context.commit('CAR_ADD', obj.data);

                    //add to cart.


                    if(obj.callback!= null){
                        obj.callback({type:'success', message:'添加成功！'});
                    }
                }
                return;
            }

            if(obj.callback!= null){
                context.commit('CAR_UPDATE', obj.data);

                if(obj.callback!= null){
                    obj.callback({type:'success', message:'本车辆已存在，选车单更新了本车的信息'});
                }
            }
        },
        CAR_UPDATE(context:any, obj:any){
            context.commit('CAR_UPDATE', obj);
        },
        CAR_REMOVE(context:any, id:string){
            context.commit('CAR_REMOVE', id);
        },
        CAR_CLEAR(context:any){
            context.commit('CAR_CLEAR');
        }
    }
}