import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse } from "axios";
import { Interceptors } from "./interceptors";

/******************************

*******************************/
export class HttpServer {
    _axios:AxiosInstance;
    // 获取axios实例
    constructor() {
        this._axios = new Interceptors().getInterceptors();
    }

    all(option:any){
        let arr:object[] = [], keys:string[] = [];
        for (let key in option) {
            keys.push(key);
            arr.push(option[key]);
        }

        return axios.all(arr).then(
            axios.spread((...args)=>{
                let result:any = [];
                for (let i = 0; i < args.length; i++) {
                    let item:AxiosResponse = args[i] as AxiosResponse;

                    if (item) {
                        let _key = keys[i] as keyof any;
                        if (item.data && item.data.data) {
                            result[_key] = item.data.data;
                        }else{
                            result[_key] = item;
                        }
                    }
                }
                return result;
            })
        );
    }

    get(url:string, config:any = null):AxiosPromise{
        return new Promise((resolve, reject) => {
            this._axios.get(url, config)
            .then((res:AxiosResponse) => {
                resolve(res);
            }).catch((err:any) => {
                reject(err);
            })
        })
    }

    post(url:string, data:any = null, config:any = null):AxiosPromise{
        return new Promise((resolve, reject) => {
            this._axios.postForm(url, data, config)
            .then((res:AxiosResponse) => {
                resolve(res);
            }).catch((err:any) => {
                reject(err);
            })
        })
    }

    request(config: any): AxiosPromise {
        return new Promise((resolve, reject) => {
            this._axios(config)
            .then((res: AxiosResponse) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err)
            });
        });
    }
}

const http = new HttpServer()

export default http