import Helper from "@/untils/common";

export default {
    namespaced: true,
    state: {
        data: localStorage.getItem('data')
    },
    getters: {
        getSearch: (state: {data: any} )=>{
            if(Helper.getVal(state.data) != null){
                return JSON.parse(state.data);
            }

            return {};
        }
    },
    mutations: {
        SEARCH_SAVE(state:any, data:any){
            console.log('saving:', data);

            state.data = JSON.stringify(data);
            localStorage.setItem('data', JSON.stringify(data));
        },

        SEARCH_REMOVE(state:any){
            state.data = '{}';
            localStorage.clear();
        }
    },
    actions: {
        SEARCH_SAVE(context:any, obj:any, callback:any=null){
            context.commit('SEARCH_SAVE', obj);

            if(callback != null){
                callback();
            }
        },
        SEARCH_REMOVE(context:any, callback:any=null){
            context.commit('SEARCH_REMOVE');
            
            if(callback != null){
                callback();
            }
        },
    }
}