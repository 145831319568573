import Helper from "@/untils/common";

export default {
    namespaced: true,
    state: {
        authorization: localStorage.getItem('user_authorization') ? localStorage.getItem('user_authorization') : '',
        type: localStorage.getItem('user_type') ? localStorage.getItem('user_type') : 'membership',
        id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '',
        openid: localStorage.getItem('user_openid') ? localStorage.getItem('user_openid') : '',
        nickname: localStorage.getItem('user_nickname') ? localStorage.getItem('user_nickname') : '',
        mobile: localStorage.getItem('user_mobile') ? localStorage.getItem('user_mobile') : '',
        rights: localStorage.getItem('user_rights') ? localStorage.getItem('user_rights') : '',
        role: localStorage.getItem('user_role') ? localStorage.getItem('user_role') : '',
    },
    getters: {
        isAdmin: (state:{type:string})=>{
            return state.type == 'manager';
        },

        getChangeCol: (state: {changeCol:boolean}) => {
            if(Helper.getVal(state.changeCol) != null){
                return state.changeCol;
            }

            return false;
        },
    },
    mutations: {
        RIGHTS_CHECK(state:any, right:string){
            if(state.type == 'manager' && state.role == 'admin') return true;

            if(Helper.getVal(state.rights) == null) return false;

            let _rights = ','  + state.rights + ',';
            return _rights.indexOf(',' + right + ',') > -1;
        },
        RIGHTS_SAVE(state:any, obj:any){
            state.role = obj.role;
            state.rights = obj.rights;

            localStorage.setItem('user_role', obj.role);
            localStorage.setItem('user_rights', obj.rights);
        },
        USER_SAVE (state:any, user:any){
            state.authorization = user.authorization;
            state.type = user.type;
            state.id = user.id;
            state.openid = user.openid;
            state.nickname = user.nickname;
            state.mobile = user.mobile;
            state.rights = user.rights;
            state.role = user.role;

            localStorage.setItem('user_authorization', user.authorization);
            localStorage.setItem('user_type', user.type);
            localStorage.setItem('user_id', user.id);
            localStorage.setItem('user_openid', user.openid);
            localStorage.setItem('user_nickname', user.nickname);
            localStorage.setItem('user_mobile', user.mobile);
            localStorage.setItem('user_rights', user.rights);
            localStorage.setItem('user_role', user.role);
        },

        USER_REMOVE(state:any){
            state.authorization = '';
            state.type = '';
            state.id = '';
            state.openid = '';
            state.nickname = '';
            state.mobile = '';
            state.rights = '';
            state.role = '';

            localStorage.setItem('user_authorization', state.authorization);
            localStorage.setItem('user_type', state.type);
            localStorage.setItem('user_id', state.id);
            localStorage.setItem('user_openid', state.openid);
            localStorage.setItem('user_nickname', state.nickname);
            localStorage.setItem('user_mobile', state.mobile);
            localStorage.setItem('user_rights', state.rights);
            localStorage.setItem('user_role', state.role);

            window.localStorage.clear();
            localStorage.clear();
        }
    },
    actions: {
        WEBSOCK_CONNECT(context:any, obj:boolean){
            context.commit('WEBSOCK_CONNECT', obj);
        },
        COL_UPDATE(context:any, obj:boolean){
            context.commit('COL_UPDATE', obj);
        },
        RIGHTS_CHECK(context:any, obj:string, callback:any=null) {
            let _bln = context.commit('RIGHTS_CHECK', obj);

            if(callback != null){
                callback(_bln);
            }else{
                return _bln;
            }
        },
        RIGHTS_SAVE(context:any, obj:any, callback:any=null){
            context.commit('RIGHTS_SAVE', obj);

            if(callback != null){
                callback();
            }
        },
        USER_SAVE(context:any, obj:any, callback:any=null){
            context.commit('USER_SAVE', obj);

            if(callback != null){
                callback();
            }
        },
        USER_REMOVE(context:any, callback:any=null){
            context.commit('USER_REMOVE');
            
            if(callback != null){
                callback();
            }
        },
    }
}