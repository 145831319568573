import { createStore } from 'vuex'
import common from './common'
import manage from './manage'
import cart from './cart'
import user from './user'
import order from './order'
import search from './search'
import socket from './socket'

const store =  createStore({
  state: {
  },
  getters: {
  },
  mutations: {
    RESET_STATE(state) {
        Object.assign(state, this.state);
    }
  },
  actions: {
    reset({ commit }) {
        commit('RESET_STATE');
    }
  },
  modules: {
    common: common,
    manage: manage,
    cart: cart,
    user: user,
    order: order,
    search: search,
    socket: socket,
  }
});

export default store;
