import { ElLoading } from 'element-plus';

let loadingInstance : ReturnType<typeof ElLoading.service>;

const startLoading = (t:string)=>{
    loadingInstance = ElLoading.service({
        fullscreen: true,
        lock: true,
        customClass: 'loading-logo',
        text: t=='' ? '加载页面...' : t,
        background: 'rgba(255,255,255,0.6)'
    })
}

const endLoading = ()=>{
    loadingInstance.close();
}

let loadingQueueCount:number = 0;
export const showLoading = (t:string='')=>{
    if(loadingQueueCount===0){
        startLoading(t);
    }
    loadingQueueCount++;
}

export const hideLoading = ()=>{
    if(loadingQueueCount <= 0) return;
    loadingQueueCount--;
    if(loadingQueueCount===0){
        endLoading();
    }
}