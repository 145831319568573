import Helper from "@/untils/common";

export default {
    namespaced: true,
    state: {
        history: sessionStorage.getItem('history') ? sessionStorage.getItem('history') : {},
        data: sessionStorage.getItem('data') ? sessionStorage.getItem('data') : {},
        menu: sessionStorage.getItem('menu') ? sessionStorage.getItem('menu') : '',
        group: sessionStorage.getItem('group') ? sessionStorage.getItem('group'): '',
        search: localStorage.getItem('search') ? localStorage.getItem('search'): {},
    },
    getters: {
        getData: (state: {data:any}) => {
            if(Helper.getVal(state.data) != null){
                if(typeof(state.data)=='string'){
                    return JSON.parse(state.data);
                }else{
                    return {};
                }
            }
            return {};
        },
        getHistory: (state: {history:JSON}) => {
            if(Helper.getVal(state.history) != null){
                return state.history;
            }
            return {};
        },
        getSearch: (state: {search:JSON}) => {
            if(Helper.getVal(state.search) != null){
                return state.search;
            }
            return {};
        },
        getMenu: (state: {menu:string}) => {
            if(Helper.getVal(state.menu) != null){
                return state.menu;
            }
            return '';
        },
        getGroup: (state: {group:JSON}) => {
            if(Helper.getVal(state.group) != null){
                return state.group;
            }
            return [];
        },
    },
    mutations: {
        SEARCH_SAVE(state:any, search:any){
            state.search = search;
            localStorage.setItem('search', JSON.stringify(search));
        },
        GROUP_SAVE (state:any, group:any){
            state.group = group;
            sessionStorage.setItem('group', group);
        },
        MENU_SAVE (state:any, menu:any){
            state.menu = menu;
            sessionStorage.setItem('menu', menu);
        },
        HISTORY_SAVE (state:any, history:any){
            state.history = history;
            sessionStorage.setItem('history', JSON.stringify(history));
        },
        DATA_SAVE (state:any, data:any){
            state.data = data;
            sessionStorage.setItem('data', JSON.stringify(data));
        },
    },
    actions: {
        DATA_SAVE(context:any, obj:any){
            context.commit('DATA_SAVE', obj);
        },
        HISTORY_SAVE(context:any, obj:any){
            context.commit('HISTORY_SAVE', obj);
        },
        MENU_SAVE(context:any, obj:any){
            context.commit('MENU_SAVE', obj);
        },
        GROUP_SAVE(context:any, obj:any){
            context.commit('GROUP_SAVE', obj);
        },
        SEARCH_SAVE(context:any, obj:any){
            context.commit('SEARCH_SAVE', obj);
        },
    }
}