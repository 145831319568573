import ajax from "@/http/index";
import  { showLoading, hideLoading } from './loading';
import Helper from './common';
import { ElMessage, ElMessageBox, FormInstance } from "element-plus";


export class DbHelper {
    static all(options:any){
        let _obj:any = {};
        options.forEach((item:any)=>{
            let url:string = Helper.getUrls(item.type);
            
            if(Helper.getVal(item.params) && item.params.loading!=undefined && item.params.loading!=null){
                if(item.params.loading_value!=undefined){
                    item.params.loading[item.params.loading_value] = true;
                }
            }
            _obj[item.key] = ajax.get(url, {fields:'*', params: item.params})
        });
        return ajax.all(_obj).finally(()=>{
            options.forEach((item:any)=>{
                if(Helper.getVal(item.params) && item.params.loading!=undefined && item.params.loading!=null){
                    if(item.params.loading_value!=undefined){
                        item.params.loading[item.params.loading_value] = false;
                    }
                }
            });
            hideLoading();
        });
    }

    static set(option:any){
        return new Promise((resolve, reject)=>{
            option.hasValid = !option.form ? false : true;

            if(option.hasValid){
                let frm:FormInstance = option.form;
                if(option.fields){
                    frm.validateField(option.fields, (valid) => {
                        if(valid){
                            DbHelper.post(option).then((res:any)=>{
                                resolve(res);
                            }).catch((err:any)=>{
                                reject(err);
                            });
                        }else{
                            reject(option.fields);
                        }
                    });
                }else{
                    frm.validate((valid, fields) => {
                        if(valid){
                            DbHelper.post(option).then((res:any)=>{
                                resolve(res);
                            }).catch((err:any)=>{
                                reject(err);
                            });
                        }else{
                            reject(fields);
                        }
                    });
                }
            }else{
                DbHelper.post(option).then((res:any)=>{
                    resolve(res);
                }).catch((err:any)=>{
                    reject(err);
                });
            }
        });
    }

    static delete(option:any){
        return new Promise((resolve, reject)=>{
            if(option.multiples.length == 0){
                reject('没有项目被选择');
                return;
            }

            if(option.hasAsk && option.t){
                ElMessageBox.confirm(option.t('sys.DELETE_ASK'), option.t('sys.PROPMPT'), {
                    confirmButtonText: option.t('sys.OK'),
                    cancelButtonText: option.t('sys.CANCEL'),
                    type: 'warning',
                }).then(()=>{
                    DbHelper.post(option).then((res:any)=>{
                        resolve(res);
                    }).catch((err:any)=>{
                        reject(err);
                    });
                }).catch(()=>{
                    reject({'msg': 'cancel'});
                });
            }else{
                DbHelper.post(option).then((res:any)=>{
                    resolve(res);
                }).catch((err:any)=>{
                    reject(err);
                });
            }
        });
    }

    static download(option:any){
        let url:string = '';
        if(Helper.getVal(option.url)!=null){
            url = option.url;
        }else{
            url = DbHelper.getUrls(option.type);
        }

        return new Promise((resolve, reject)=>{
            ajax.get(url, {
                params: option.params,
                responseType: 'blob',
                onDownloadProgress: option.onDownloadProgress
            }).then((response:any)=>{
                let url = window.URL.createObjectURL(new Blob([response.data]));
                if (process.env.NODE_ENV !== 'development') {
                    url = url.replace('http://','https://');
                }
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', option.filename);
                document.body.appendChild(link);
                link.click();
            }).catch((err: any)=>{
                reject(err);
            })
        });
    }

    static get(option:any){
        let url:string = '';
        if(Helper.getVal(option.url)!=null){
            url = option.url;
        }else{
            url = DbHelper.getUrls(option.type);
        }
            
        if(option.loading!=undefined && option.loading!=null){
            if(option.loading===true){
                showLoading();
            }else{
                if(option.loading_value!=undefined){
                    option.loading[option.loading_value] = true;
                }
            }
        }

        if(Helper.getVal(option.headers)==null){
            option.headers = {};
        }

        return new Promise((resolve, reject)=>{
            ajax.get(url, {params: option.params, headers: option.headers })
            .then((res: any)=>{
                resolve(res);
            })
            .catch((err: any)=>{
                reject(err);
                
            }).finally(()=>{
                if(option.loading_value!=undefined){
                    option.loading[option.loading_value] = false;
                }
                hideLoading();
            });
        });
    }

    static post(option:any){
        let url:string = DbHelper.getUrls(option.type);
            
        if(option.loading!=undefined && option.loading!=null){
            if(option.loading===true){
                showLoading();
            }else{
                if(option.loading_value!=undefined){
                    option.loading[option.loading_value] = true;
                }
            }
        }

        if(Helper.getVal(option.config)==null){
            option.config = {};
        }

        if(Helper.getVal(option.config.headers)==null){
            option.config.headers = {};
        }

        return new Promise((resolve, reject)=>{
            ajax.post(url, option.params, option.config)
            .then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            }).finally(()=>{
                if(option.loading_value!=undefined){
                    option.loading[option.loading_value] = false;
                }
                hideLoading();
            });
        });
    }
    
    static getUrls(opt:string){
        let url:string = Helper.getVal(Helper.Urls[opt], '');
        if(url === '') throw opt + ' Error, Url is not exists';
        return url;
    }
}

export default DbHelper