import Helper from "@/untils/common";

export default {
    namespaced: true,
    state: {
        orderinfo: localStorage.getItem('orderinfo') ? localStorage.getItem('orderinfo') : null,
    },
    getters: {
        getOrderinfo: (state: { orderinfo: any; })=>{
            if(Helper.getVal(state.orderinfo) != null){
                if(!Helper.isJson(state.orderinfo)){
                    return JSON.parse(state.orderinfo);
                }
                return state.orderinfo;
            }
            return {};
        },
    },
    mutations: {
        INFO_SAVE (state:any, orderinfo:any){
            state.orderinfo = orderinfo;
            localStorage.setItem('orderinfo', JSON.stringify(state.orderinfo));
        },
        INFO_REMOVE(){
            localStorage.setItem('orderinfo', JSON.stringify([]));
        }
    },
    actions: {
        INFO_SAVE(context:any, obj:any, callback:any=null){
            context.commit('INFO_SAVE', obj);

            if(callback != null){
                callback();
            }
        },
        INFO_REMOVE(context:any, callback:any=null){
            context.commit('INFO_REMOVE');
            
            if(callback != null){
                callback();
            }
        },
    }
}