import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import { createI18n } from 'vue-i18n'

import { axiosPlugin } from './plugins/axios'
import Print from 'vue3-print-nb';
import 'element-plus/dist/index.css'

const i18n = createI18n({
  legacy: false,
  globalInjection:true,
  locale:'zh',
  fallbackLocale: 'zh',
  messages: {
    zh:require('@/assets/lang/system/zh-cn.json'),
    en:require('@/assets/lang/system/en.json')
  }
  });

const app = createApp(App);
app.use(store).use(router).use(ElementPlus, { locale: zhCn, }).use(i18n).use(axiosPlugin).use(Print).mount('#app')
app.config.globalProperties.$params = {};