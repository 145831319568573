import Helper from "@/untils/common";

export default {
    namespaced: true,
    state: {
        authorization: localStorage.getItem('authorization') ? localStorage.getItem('authorization') : '',
        type: localStorage.getItem('type') ? localStorage.getItem('type') : 'membership',
        id: localStorage.getItem('id') ? localStorage.getItem('id') : '',
        openid: localStorage.getItem('openid') ? localStorage.getItem('openid') : '',
        nickname: localStorage.getItem('nickname') ? localStorage.getItem('nickname') : '',
        mobile: localStorage.getItem('mobile') ? localStorage.getItem('mobile') : '',
        rights: localStorage.getItem('rights') ? localStorage.getItem('rights') : '',
        role: localStorage.getItem('role') ? localStorage.getItem('role') : '',
        isConnected: localStorage.getItem('isConnected') ? localStorage.getItem('isConnected') == '1' : false,
        expiredate: localStorage.getItem('expiredate') ? localStorage.getItem('expiredate') : '',
        scoketid: sessionStorage.getItem('scoketid') ? sessionStorage.getItem('scoketid') : '',
    },
    getters: {
        isAdmin: (state:{rights:string})=>{
            return state.rights == 'system-all';
        },
        rights: (state: {rights:string})=>{
            return state.rights;
        },
        scoketid: (state: {scoketid:string})=>{
            return state.scoketid;
        }
    },
    mutations: {
        USER_EXPIRE(state:{expiredate:string}, expire: Date){
            state.expiredate = expire.getTime().toString();
            localStorage.setItem('expiredate', state.expiredate);
        },
        WEBSOCK_ID(state:{scoketid:string}, id: string){
            state.scoketid = id;
            sessionStorage.setItem('scoketid', state.scoketid);
        },
        USER_SAVE (state:any, user:any){
            state.authorization = user.authorization;
            state.type = user.type;
            state.id = user.id;
            state.openid = user.openid;
            state.nickname = user.nickname;
            state.mobile = user.mobile;
            state.rights = user.rights;
            state.role = user.role;
            state.expiredate = user.expiredate;

            localStorage.setItem('authorization', user.authorization);
            localStorage.setItem('type', user.type);
            localStorage.setItem('id', user.id);
            localStorage.setItem('openid', user.openid);
            localStorage.setItem('nickname', user.nickname);
            localStorage.setItem('mobile', user.mobile);
            localStorage.setItem('rights', user.rights);
            localStorage.setItem('role', user.role);
            localStorage.setItem('expiredate', user.expiredate);
        },
        USER_REMOVE(state:any){
            state.authorization = '';
            state.type = '';
            state.id = '';
            state.openid = '';
            state.nickname = '';
            state.mobile = '';
            state.rights = '';
            state.role = '';
            state.expiredate = '';

            localStorage.setItem('authorization', state.authorization);
            localStorage.setItem('type', state.type);
            localStorage.setItem('id', state.id);
            localStorage.setItem('openid', state.openid);
            localStorage.setItem('nickname', state.nickname);
            localStorage.setItem('mobile', state.mobile);
            localStorage.setItem('rights', state.rights);
            localStorage.setItem('role', state.role);
            localStorage.setItem('expiredate', state.expiredate);

            window.localStorage.clear();
            localStorage.clear();
        }
    },
    actions: {
        USER_EXPIRE(context:any, obj:boolean){
            context.commit('USER_EXPIRE', obj);
        },
        WEBSOCK_ID(context:any, obj:string){
            context.commit('WEBSOCK_ID', obj);
        },
        USER_SAVE(context:any, obj:any, callback:any=null){
            context.commit('USER_SAVE', obj);

            if(callback != null){
                callback();
            }
        },
        USER_REMOVE(context:any, callback:any=null){
            context.commit('USER_REMOVE');
            
            if(callback != null){
                callback();
            }
        },
    }
}