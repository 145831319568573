import Helper from "@/untils/common";

export default {
    namespaced: true,
    state: {
        id: '' as string,
        websocket:null as WebSocket | null,
        rec:null as any,
        webSocketMessage: null as any,
        isConnected: false as boolean,
    },
    getters: {
        getMessage(state:any){
            if(state.websocket){
                return state.webSocketMessage;
            }
            return '';
        }
    },
    mutations: {
        createWebSocket(state:any, options:any){
            state.id = options.id;
            createWebSocket(state, options);
        },

        initWebSocket(state:any, options:any){
            initWebSocket(state, options);
        },

        reConnect(state:any, options:any){
            reConnect(state, options);
        },

        websocket_Send(state:any, data:any){
            if(state.websocket){
                state.websocket.send(data);
            }else{
                Helper.Debug(null, 'WebSocket No Exists');
            }
        }
    },
    actions: {
        initWebSocket(context:any, options:any){
            let _path:any = Helper.BaseUrl;
            options.Url = `${_path.replace('http', 'ws')}:20002`;

            context.commit('createWebSocket', options);
        },
        websocket_Send(context:any, data:any){
            context.commit('websocket_Send', data);
        },
        websocket_Message(context:any, data:any){
            context.commit('websocket_Message', data);
        },
    }
}

function reConnect(state: any, options: any) {
    Helper.Debug(null, 'Try ReConnection WebSocket');
    if(state.isConnected) return;

    //延迟5秒，防止频繁连接
    state.rec && clearTimeout(state.rec);
    state.rec = setTimeout(()=>{
        createWebSocket(state, options);
    }, 5000);
}

function createWebSocket(state: any, options: any) {
    console.log('============ Check WebSocket ============');
    if('WebSocket' in window){
        console.log('支持WebSocket');
    }else if('MozWebSocket' in window){
        console.log('支持MozWebSocket');
    }else{
        console.log('不支持MozWebSocket');
    }

    try{
        initWebSocket(state, options);
    }catch(e){
        reConnect(state, options);
    }
}

function initWebSocket(state:any, options:any) {
    if (process.env.NODE_ENV === 'development') {
        let wsUrl:string = options.Url;
        state.websocket =  new WebSocket(wsUrl);
    }else{
        state.websocket = new WebSocket('wss://jiatengbus.com:20002');
    }
    console.log('WebSocket: ', state.websocket);

    state.websocket.onopen = ()=>{
        console.log('============ WebSocket Connection Success ============');
        //this.websocket_Open();
    }

    state.websocket.onmessage = (e:MessageEvent<any>)=>{
        if(Helper.getVal(e.data)!=null){
            state.webSocketMessage = JSON.parse(e.data);
        }else{
            state.webSocketMessage = {};
        }
    }

    state.websocket.onerror = ()=>{
        console.log('============ WebSocket Error ============');
        reConnect(state, options);
    }

    state.websocket.onclose = (e:any)=>{
        console.log('============ Close WebSocket ============', e);
    }
}